import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout/layout";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
} from "react-share";
import styled from "styled-components";

const Article = styled.article`
  width: 100%;
  padding: ${(props) => props.theme.spacing.twoX};
  box-sizing: border-box;
`;

const ShareContainer = styled.div`
  padding: ${(props) => props.theme.spacing.oneX};
  & button {
    margin: ${(props) => props.theme.spacing.oneX};
  }
`;

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark, site } = data; // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark;
  const { siteMetadata } = site;
  return (
    <Layout>
      <Article>
        <h1>{frontmatter.title}</h1>
        <h4>{frontmatter.date}</h4>
        <div
          className="blog-post-content"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </Article>
      <ShareContainer>
        <div>Sharing is caring</div>
        <FacebookShareButton
          url={`${siteMetadata.siteUrl}/${frontmatter.slug}`}
          quote={markdownRemark.excerpt}
        >
          <FacebookIcon size="50px" />
        </FacebookShareButton>
        <LinkedinShareButton
          url={`${siteMetadata.siteUrl}/${frontmatter.slug}`}
          summary={markdownRemark.excerpt}
          title={frontmatter.title}
          source={siteMetadata.siteUrl}
        >
          <LinkedinIcon size="50px" />
        </LinkedinShareButton>
        <TwitterShareButton
          url={`${siteMetadata.siteUrl}/${frontmatter.slug}`}
          title={frontmatter.title}
          via={siteMetadata.siteUrl}
        >
          <TwitterIcon size="50px" />
        </TwitterShareButton>
      </ShareContainer>
    </Layout>
  );
}

export const pageQuery = graphql`
  query ($slug: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      excerpt(pruneLength: 250)
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
  }
`;
